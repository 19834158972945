<template>
  <SideBar @toggle="$emit('toggle')" icon="code" ref="sideBar">
    <div class="">
      <a class="dropdown-item has-text-grey-dark has-text-weight-semibold" v-show="showLibs" @click="showLibsSideBar">
        <font-awesome-icon icon="plus-square" class="has-text-grey-dark"/>&nbsp;&nbsp;&nbsp;External Libraries (from Maven repo)
      </a>
      <hr class="sidebar-divider" v-show="showLibs">
      <IdeNewProject @closeBar="closeBar" :language="language"/>
      <IdeMyProjects :language="this.language" :languageName = "languageName" :projectId = "projectId" :aceLanguageCode = "aceLanguageCode"
                     :isMultiFileLang="isMultiFileLang" :blocklyWorkspace="blocklyWorkspace"/>
      <a class="dropdown-item has-text-weight-semibold" @click="showRecentsSideBar" v-if="!isMultiFileLang">
        <font-awesome-icon icon="history" class="has-text-grey-dark"/>&nbsp;&nbsp;Execute History
      </a>
      <a class="dropdown-item has-text-weight-semibold" @click="collaborate">
        <font-awesome-icon icon="video" class="has-text-grey-dark"/>&nbsp;&nbsp;Collaborate/Peer Programming
      </a>
      <hr class="sidebar-divider">
      <IdeSave :language= "language" mode="Save" :versionIndex="versionIndex" :project="project" :libraries="libraries"
               :isMultiFileLang="isMultiFileLang" :projectKey="projectKey" :blocklyWorkspace="blocklyWorkspace" @toggle="closeBar"/>
      <a class="dropdown-item" v-show="project && project.id">
        <div class="field is-paddingless is-marginless">
          <input id="autoSave"  v-model="autoSaveOnOption"  value="true" type="checkbox" name="autosaveon" class="switch is-info" @change="autoSaveChange"/>&nbsp;&nbsp;
          <label for="autoSave" class="has-text-weight-semibold auto-save-text">Auto Save On Execute</label>
        </div>
      </a>
      <IdeSave :language= "language" mode="SaveAs" :versionIndex="versionIndex" :project="project" :libraries="libraries"
               :isMultiFileLang="isMultiFileLang" :projectKey="projectKey"  :blocklyWorkspace="blocklyWorkspace"  @toggle="closeBar"/>
      <IdeShare :language= "language" :project="project" @toggle="closeBar" :isMultiFileLang="isMultiFileLang"/>
      <a class="dropdown-item has-text-weight-semibold" @click="showHideInstantShare">
        <font-awesome-icon icon="share-square" class="has-text-grey-dark"/>&nbsp;&nbsp;Instant Share <span v-if="!isMultiFileLang">- Embed</span>
        <span class="is-size-7 has-text-centered"> (No Login/Save required)</span>
      </a>
      <IdeInstantShare :language= "language" @toggle="closeBar" @closeInstantShare="showHideInstantShare"
                       :versionIndex="versionIndex" :project="project" :libraries="libraries" :blocklyWorkspace="blocklyWorkspace"
                       :isMultiFileLang="isMultiFileLang" :projectKey="projectKey" v-if="showInstantShare"/>
      <a class="dropdown-item has-text-weight-semibold" @click="copyToClipboard" v-if="language!=='html'">
        <font-awesome-icon icon="copy" class="has-text-grey-dark"/>&nbsp;&nbsp;Copy to Clipboard
      </a>
      <div class="ide-menu-box" v-bind:class="{'is-active': showCopiedMessage}">
        <div class="box">
          <div class="has-text-centered confirm-text">{{showCopiedMessageText}}</div>
          <div class="has-text-centered">
            <button class="button is-small is-rounded has-text-weight-semibold" @click="showCopiedMessage=false">Close</button>
          </div>
        </div>
      </div>
      <hr class="sidebar-divider">
      <div class="dropdown-item">
      <font-awesome-icon icon="moon" class="has-text-grey-dark"/>
        <span class="field is-paddingless is-marginless">
          <input id="switchTheme"  v-model="darkTheme" value="true" type="checkbox" name="switchTheme" class="switch is-info is-rtl" @change="toggleTheme"/>&nbsp;&nbsp;
          <label for="switchTheme" class="has-text-weight-semibold auto-save-text has-text-grey-dark">Dark Theme</label>
        </span>
      </div>
      <div class="dropdown-item has-text-weight-semibold">
        <font-awesome-icon icon="font" class="has-text-grey-dark"/>&nbsp;&nbsp;Font Size
        <select v-model="fSize" v-on:change="changeFont">
          <option v-for="n in 15" v-bind:value="n+10" v-bind:key="n">
            {{n + 10}}
          </option>
        </select>
      </div>
      <hr class="sidebar-divider">
      <a class="dropdown-item has-text-weight-semibold" v-if="language!=='html' && !isMultiFileLang">
        <input type="file" id="openFile" class="is-hidden" v-on:change="readFileFromLocalDisk"/>
        <label for="openFile"><font-awesome-icon icon="folder-open" class="has-text-grey-dark"/>&nbsp;&nbsp;Open (from local file)</label>
      </a>
      <a class="dropdown-item has-text-weight-semibold" @click="download" v-if="!isMultiFileLang">
        <font-awesome-icon icon="download" class="has-text-grey-dark"/>&nbsp;&nbsp;Save (to local file)
      </a>
      <hr class="sidebar-divider has-text-weight-semibold" v-if="!isMultiFileLang">
      <a class="dropdown-item has-text-weight-semibold"  @click="prettyPrint"  v-if="!isMultiFileLang">
        <font-awesome-icon icon="print" class="has-text-grey-dark"/>&nbsp;&nbsp;Pretty Print
      </a>
      <a href="https://docs.jdoodle.com" class="dropdown-item has-text-weight-semibold" target="_blank">
        <font-awesome-icon icon="book" class="has-text-grey-dark"/>&nbsp;&nbsp;How To / FAQ
      </a>
    </div>
  </SideBar>
</template>

<script>
import SideBar from './Utils/SideBar'
import { eventBus } from './../assets/javascript/event-bus'
import IdeNewProject from './IDEMenuItems/IdeNewProject'
import IdeMyProjects from './IDEMenuItems/IdeMyProjects'
import IdeSave from './IDEMenuItems/IdeSave'
import IdeShare from './IDEMenuItems/IdeShare'
import { GURU_EVENTS, IDE_CONST } from '../assets/javascript/constants'
import { saveAs } from 'file-saver'
import { HTML_IDE_UTIL } from '../assets/javascript/html-ide-util'
import IdeInstantShare from './IDEMenuItems/IdeInstantShare'

export default {
  name: 'ideMenu',
  components: { IdeInstantShare, IdeShare, IdeSave, IdeMyProjects, IdeNewProject, SideBar },
  data () {
    return {
      autoSaveOnOption: false,
      showCopiedMessage: false,
      showCopiedMessageText: '',
      darkTheme: false,
      showInstantShare: false,
      fSize: null
    }
  },
  props: ['language', 'languageName', 'libraries', 'project', 'versions', 'storage', 'versionIndex', 'autoSaveOn', 'downloadFileName',
    'aceLanguageCode', 'isMultiFileLang', 'projectKey', 'fontSize', 'blocklyWorkspace'],
  computed: {
    showLibs () {
      return this.language === 'java'
    },
    projectId () {
      return (this.project) ? this.project.id : null
    }
  },
  mounted () {
    this.autoSaveOnOption = this.autoSaveOn
    this.darkTheme = this.$store.state.dark
    this.fSize = this.fontSize
  },
  methods: {
    showLibsSideBar () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./IdeLibraries'), { libraries: this.libraries })
    },
    changeFont () {
      eventBus.$emit('IdeChangeFontSize', this.fSize)
    },
    showRecentsSideBar () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./ExecutionHistory'),
        { versions: this.versions, language: this.language, languageName: this.languageName, storage: this.storage, aceLanguageCode: this.aceLanguageCode })
    },
    closeBar () {
      this.$refs.sideBar.emitToggle()
    },
    collaborate: function () {
      window.TogetherJS(this)
      this.closeBar()
    },
    autoSaveChange () {
      eventBus.$emit((this.language === 'html') ? 'HtmlIdeAutoSave' : 'IdeAutoSave', this.autoSaveOnOption)
    },
    copyToClipboard: function () {
      window.navigator.clipboard.writeText(window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue()).then((e) => {
        this.showCopiedMessage = true
        this.showCopiedMessageText = 'Program copied to clipboard'
      }, (e) => {
        this.showCopiedMessage = true
        this.showCopiedMessageText = 'Copy to clipboard failed'
      })
    },
    download () {
      let fileName
      let code
      if (this.language === 'html') {
        fileName = 'jdoodle.html'
        code = HTML_IDE_UTIL.getHtmlCode(window.ace.edit(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME).getSession().getValue(),
          window.ace.edit(IDE_CONST.HTML_HEAD_EDITOR_NAME).getSession().getValue(),
          window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().getValue(),
          window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().getValue(),
          window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().getValue())
      } else {
        code = window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue()
        fileName = this.downloadFileName
        if (this.language === 'java') {
          // eslint-disable-next-line no-useless-escape
          let regex = /(public)([\s]*)(class)([\s]*)([\w\$]*)([\s]*)({)/
          let match = regex.exec(code)
          if (match && match.length >= 5) {
            fileName = match[5] + '.java'
          } else {
            fileName = 'jdoodle.java'
          }
        }
      }

      let file = new File([code], fileName, { type: 'text/plain;charset=utf-8' })
      saveAs(file)
    },
    toggleTheme () {
      this.$store.commit('toggleTheme')
      if (this.darkTheme) {
        window.ace.edit(IDE_CONST.CODE_EDITOR).setTheme('ace/theme/gruvbox')
      } else {
        window.ace.edit(IDE_CONST.CODE_EDITOR).setTheme('ace/theme/xcode')
      }
    },
    readFileFromLocalDisk (e) {
      let file = e.target.files[0]
      if (!file) {
        return
      }
      let reader = new FileReader()
      reader.onload = (e) => {
        window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(e.target.result)
        this.closeBar()
      }
      reader.readAsText(file)
    },
    prettyPrint () {
      this.closeBar()
      window.print()
    },
    showHideInstantShare () {
      this.showInstantShare = !this.showInstantShare
    }
  }
}
</script>

<style lang="scss">
  @import "../../node_modules/bulma-switch/dist/css/bulma-switch.min.css";

  .sidebar-divider {
    height: 2px;
    margin: 1.5px;
  }

  .ide-menu-box, .ide-menu-form-box {
    height: 0;
    overflow: hidden;
    transition: height 0.8s ease-in-out;
    -webkit-transition: height 0.8s ease-in-out;
  }

  .ide-menu-box > .box , .ide-menu-form-box > .box {
    margin: 1em;
    background: hsl(0, 0%, 96%);
  }

  .ide-menu-box.is-active {
    height: 200px;
  }

  .ide-menu-form-box.is-active {
    height: 250px;
  }

  .confirm-text {
    font-size: 0.9em;
    margin-bottom: 1em;
    color: hsl(0, 0%, 29%);
  }

  .switch[type=checkbox]+label.auto-save-text {
    font-size: 0.875rem;
  }

  .dropdown-item {
     color: hsl(0, 0%, 21%);
  }

</style>
