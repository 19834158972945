<template>
  <div class="columns is-mobile is-multiline">
    <div class="column is-mobile">
      <Spinner v-show="showInProgressSection" :message="inProgressMessage"/>
      <div v-show="showSuccessSection">
        <slot name="successMessage">
          <DoodleMessage :message="successMessage"
                       message-class="has-text-success"/>
        </slot>
      </div>
      <div>
        <div>
          <div v-show="showMainSection">
              <slot></slot>
          </div>
          <div class="has-text-centered">
            <slot name="errorMessage">
              <DoodleMessage :message="errorMessageText" message-class="has-text-danger"/>
            </slot>
              <ActionCloseMiniButton :action-text="actionText" :showAction="showAction"
                                 @click="$emit('action')" @close="$emit('close')"/>
          </div>
          <div v-show="showMainSection">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Utils/Spinner'
import DoodleMessage from '@/components/Utils/DoodleMessage'
import ActionCloseMiniButton from './ActionCloseMiniButtons'

export default {
  name: 'doodleGeneralMiniForm',
  inject: ['$validator'],
  components: { ActionCloseMiniButton, DoodleMessage, Spinner },
  props: ['title', 'inProgressMessage', 'successMessage', 'actionText', 'form', 'errorMessageText', 'inProgress', 'completed'],
  data: function () {
    return {
      generalForm: this.form
    }
  },
  computed: {
    showMainSection () {
      return !this.inProgress && !this.completed
    },
    showInProgressSection () {
      return this.inProgress
    },
    showSuccessSection () {
      return !this.inProgress && !this.errorMessageText && this.completed
    },
    showAction () {
      return !this.inProgress && !this.completed
    }
  }
}
</script>

<style scoped lang="scss">

</style>
