<template>
  <div>
    <a class="dropdown-item has-text-weight-semibold" @click="showLoginMessage=!showLoginMessage">
      <font-awesome-icon icon="file-alt" class="has-text-grey-dark semi"/>&nbsp;&nbsp;New Project/ Clear All
    </a>
    <div class="ide-menu-box"  v-bind:class="{'is-active': showLoginMessage}">
      <div class="box">
        <div class="has-text-centered confirm-text">Do you want to clear current project?</div>
        <div class="columns is-mobile" >
          <div class="column is-6 has-text-right">
            <button class="button is-small is-rounded has-text-weight-bold is-info" @click="clearProject">Yes</button>
          </div>
          <div class="column is-6 has-text-left">
            <button class="button is-small is-rounded has-text-weight-semibold" @click="showLoginMessage=false">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'

export default {
  name: 'ideNewProject',
  props: ['language'],
  data () {
    return {
      showLoginMessage: false
    }
  },
  methods: {
    clearProject () {
      eventBus.$emit((this.language === 'html') ? 'HtmlIdeClearProject' : 'IdeClearProject')
      this.$emit('closeBar')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
