<template>
  <div>
    <a class="dropdown-item has-text-weight-semibold" @click="openMyProjects">
      <font-awesome-icon icon="folder" class="has-text-grey-dark"/>&nbsp;&nbsp;My Projects
    </a>
    <IdeSignInMessage :showLoginMessage="showLoginMessage" @closeLoginMessage="closeLoginMessage"
                      loginMessage="Please login to see your projects"/>
  </div>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'
import IdeSignInMessage from './IdeSignInMessage'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'ideMyProjects',
  components: { IdeSignInMessage },
  data () {
    return {
      showLoginMessage: false
    }
  },
  props: ['language', 'languageName', 'projectId', 'aceLanguageCode', 'isMultiFileLang'],
  methods: {
    openMyProjects () {
      if (this.$store.state.isUserLoggedIn === true) {
        eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../MyProjects'), {
          language: this.language,
          languageName: this.languageName,
          projectId: this.projectId,
          aceLanguageCode: this.aceLanguageCode,
          isMultiFileLang: this.isMultiFileLang
        })
      } else {
        this.showLoginMessage = !this.showLoginMessage
      }
    },
    closeLoginMessage () {
      this.showLoginMessage = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
