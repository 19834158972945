<template>
  <div>
    <a class="dropdown-item has-text-weight-semibold" @click="showShare">
      <font-awesome-icon icon="share-square" class="has-text-grey-dark"/>&nbsp;&nbsp;Editable Share <span v-if="!isMultiFileLang">- Embed in a Blog or Site</span>
    </a>
    <IdeSignInMessage :showLoginMessage="showLoginMessage" @closeLoginMessage="closeLoginMessage"
                      loginMessage="Please login to share the project"/>
    <div class="ide-menu-form-box"  v-bind:class="{'is-active': showShareDetail}">
      <div class="box is-paddingless">
        <div class="has-text-weight-semibold has-text-centered is-size-7 copied-to-clipboard" v-show="copiedToClip">{{showCopiedMessageText}}</div>
        <div class="share-div">
          Share URL <br/><span class="share-box" v-if="project">{{project.url}}</span>
          <a @click="copyToClipboard(project.url)"><font-awesome-icon icon="copy" class="has-text-danger"/></a>
        </div>
        <div class="share-div" v-if="project && project.embedUrl && !isMultiFileLang">
          Embed URL <br/><span class="share-box">{{project.embedUrl}}</span>
          <a @click="copyToClipboard(project.embedUrl)"><font-awesome-icon icon="copy" class="has-text-danger"/></a>
        </div>
        <div class="columns is-mobile" >
          <div class="column is-6 has-text-right" v-show="hasProjectId">
            <button class="button is-small is-rounded has-text-weight-bold is-danger" @click="showUnshare">Unshare</button>
          </div>
          <div class="column" v-bind:class="{'is-12 has-text-centered': !hasProjectId, 'is-6 has-text-left': hasProjectId}">
            <button class="button is-small is-rounded has-text-weight-semibold" @click="showShareDetail=false">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="ide-menu-box"  v-bind:class="{'is-active': showSaveBox}">
      <div class="box">
        <div class="has-text-centered confirm-text">Please save the project to share</div>
        <div class="has-text-centered">
          <button class="button is-small is-rounded has-text-weight-semibold" @click="showSaveBox=false">Close</button>
        </div>
      </div>
    </div>
    <div class="ide-menu-form-box"  v-bind:class="{'is-active': showShareBox}">
      <div class="box">
        <DoodleGeneralMiniForm title=""
                           in-progress-message="JDoodle is trying to share the project, Please wait..."
                           :success-message="successMessage"
                           :action-text="shareActionText"
                           :form="doodleForm"
                           :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                           :errorMessageText="doodleForm.errorMessage"
                           @action="shareProject"
                           @close="saveCloseAction"
                            isMini="true">
          <div class="has-text-centered confirm-text">{{confirmText}}</div>
        </DoodleGeneralMiniForm>
      </div>
    </div>
  </div>
</template>

<script>
import IdeSignInMessage from './IdeSignInMessage'
import formMixin from '../../assets/javascript/form-mixin'
import DoodleGeneralMiniForm from '../Utils/DoodleGeneralMiniForm'

export default {
  name: 'ideShare',
  components: { DoodleGeneralMiniForm, IdeSignInMessage },
  mixins: [formMixin],
  data () {
    return {
      showLoginMessage: false,
      showShareBox: false,
      showSaveBox: false,
      showShareDetail: false,
      copiedToClip: false,
      showCopiedMessageText: '',
      isShare: false
    }
  },
  props: ['project', 'language', 'isMultiFileLang'],
  computed: {
    shareActionText () {
      return (this.isShare) ? 'Share' : 'Unshare'
    },
    confirmText () {
      return (this.isShare) ? 'Do you want to share this project?' : 'Do you want to unshare this project?'
    },
    successMessage () {
      return (this.isShare) ? 'Project Successfully Unshared.' : 'Project Successfully Shared.'
    },
    hasProjectId () {
      return this.project && this.project.id
    }
  },
  mounted () {
    this.isShare = !this.project || this.project.shared !== true
  },
  methods: {
    showShare () {
      if (this.$store.state.isUserLoggedIn === true) {
        if (this.project && this.project.shared) {
          this.showShareDetail = !this.showShareDetail
        } else if (this.project && this.project.id) {
          this.showShareBox = !this.showShareBox
        } else {
          this.showSaveBox = !this.showSaveBox
        }
      } else {
        if (this.project && this.project.shared) {
          this.showShareDetail = !this.showShareDetail
        } else {
          this.showLoginMessage = !this.showLoginMessage
        }
      }
    },
    saveCloseAction () {
      if (this.doodleForm.completed) {
        this.$emit('toggle')
      } else {
        this.showShareBox = false
      }
    },
    closeLoginMessage () {
      this.showLoginMessage = false
    },
    showUnshare () {
      this.showShareBox = true
      this.showShareDetail = false
    },
    copyToClipboard: function (txt) {
      window.navigator.clipboard.writeText(txt).then((e) => {
        this.copiedToClip = true
        this.showCopiedMessageText = 'Copied to clipboard!'
      }, (e) => {
        this.copiedToClip = true
        this.showCopiedMessageText = 'Copy to clipboard failed!'
      })

      this.$_.delay(() => { this.copiedToClip = false }, 3000)
    },
    shareProject () {
      let url = '/api/doodle/'
      let event

      if (this.isShare) {
        url += 'share'
        event = 'share'
      } else {
        url += 'unshare'
        event = 'unshare'
      }

      this.executeAPI({
        url: url,
        data: { id: this.project.id, lang: this.language },
        method: 'post',
        successAction: (data) => {
          if (this.isShare) {
            this.project.url = data.url
            this.project.embedUrl = data.embedUrl
          } else {
            this.project.url = null
            this.project.embedUrl = null
          }

          this.project.shared = !this.project.shared
          this.isShare = !this.isShare
          if (this.project.shared) {
            this.showShareBox = false
            this.showShareDetail = true
          } else {
            this.doodleForm.completed = true
          }
        },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.IDE,
        jdaEvent: event,
        jdaLabel: this.language
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .share-div {
    font-size: 0.9em;
    font-weight: 600;
    padding: 0.6em;
  }

  .share-box {
    display: inline;
    padding: 0.5em;
  }

  .copied-to-clipboard {
    padding-top: 0.8em;
  }
</style>
