<template>
  <div class="level is-mobile action-close-buttons">
    <div class="level-item" v-show="showAction">
      <button class="button is-rounded is-info has-text-black has-text-weight-semibold" @click="$emit('click')" type="button">
        {{actionText}}
      </button>
    </div>
    <div class="level-item">
      <button class="button is-rounded has-text-weight-semibold" type="button" @click="$emit('close')">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionCloseMiniButton',
  props: ['actionText', 'showAction']
}
</script>

<style scoped lang="scss">
  .action-close-buttons {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
</style>
