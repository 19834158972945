<template>
  <div>
    <a class="dropdown-item has-text-weight-semibold" @click="showSave">
      <font-awesome-icon icon="folder" class="has-text-grey-dark"/>&nbsp;&nbsp;{{saveActionText}}
    </a>
    <IdeSignInMessage :showLoginMessage="showLoginMessage" @closeLoginMessage="closeLoginMessage"
                      loginMessage="Please login to save the projects"/>
    <div class="ide-menu-form-box"  v-bind:class="{'is-active': showSaveBox}">
      <div class="box">
        <DoodleGeneralMiniForm title=""
                           in-progress-message="JDoodle is trying to save the project, Please wait..."
                           success-message="Project Successfully Saved."
                           :action-text="saveActionText"
                           :form="doodleForm"
                           :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                           :errorMessageText="doodleForm.errorMessage"
                           @action="saveProject"
                           @close="saveCloseAction"
                            isMini="true">
          <DoodleInput field-name="projectName" label-text="Project Name" type="text"
                       left-icon="address-card"
                       rule="required"
                       v-model="doodleForm.projectName" place-holder="Project Name"/>
        </DoodleGeneralMiniForm>

      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'
import IdeSignInMessage from './IdeSignInMessage'
import formMixin from '../../assets/javascript/form-mixin'
import DoodleInput from '../Utils/DoodleInput'
import DoodleGeneralMiniForm from '../Utils/DoodleGeneralMiniForm'
import { IDE_CONST } from '../../assets/javascript/constants'

export default {
  name: 'ideSave',
  components: { DoodleGeneralMiniForm, IdeSignInMessage, DoodleInput },
  mixins: [formMixin],
  data () {
    return {
      showLoginMessage: false,
      showSaveBox: false,
      doodleForm: {
        projectName: null
      }
    }
  },
  props: ['language', 'mode', 'versionIndex', 'project', 'javaLibraries', 'libraries', 'isMultiFileLang', 'projectKey', 'blocklyWorkspace'],
  computed: {
    isSaveAs () {
      return this.mode === 'SaveAs'
    },
    saveActionText () {
      return (this.mode === 'SaveAs') ? 'Save As' : 'Save'
    }
  },
  methods: {
    showSave () {
      if (this.$store.state.isUserLoggedIn === true) {
        if (!this.isSaveAs && this.project && this.project.id && !this.showSaveBox) {
          this.saveProjectActual()
        } else {
          this.showSaveBox = !this.showSaveBox
        }
      } else {
        this.showLoginMessage = !this.showLoginMessage
      }
    },
    saveCloseAction () {
      if (this.doodleForm.completed) {
        this.$emit('toggle')
      } else {
        this.showSaveBox = false
      }
    },
    closeLoginMessage () {
      this.showLoginMessage = false
    },
    saveProject () {
      this.executeAPI({
        url: '/api/doodle/checkFileName',
        data: { filename: this.doodleForm.projectName, lang: this.language, isMultiFile: this.isMultiFileLang },
        method: 'post',
        successAction: () => { this.saveProjectActual() },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.IDE,
        jdaEvent: 'check-file-name',
        jdaLabel: this.language
      })
    },
    getSanitizedScriptIt (root) {
      if (root.content) {
        root.content = null
      }

      if (root.codeChanged) {
        root.codeChanged = false
      }

      if (root.children) {
        for (let child of root.children) {
          this.getSanitizedScriptIt(child)
        }
      }
    },
    saveProjectActual () {
      this.showSaveBox = true

      let data

      if (this.language === 'html') {
        data = {
          htmlBody: window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().getValue(),
          htmlHead: window.ace.edit(IDE_CONST.HTML_HEAD_EDITOR_NAME).getSession().getValue(),
          jsCode: window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().getValue(),
          cssCode: window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().getValue(),
          docType: window.ace.edit(IDE_CONST.HTML_DOC_TYPE_EDITOR_NAME).getSession().getValue()
        }
      } else if (this.language === 'blockly') {
        data = {
          script: JSON.stringify(window.Blockly.serialization.workspaces.save(this.blocklyWorkspace)),
          versionIndex: this.versionIndex,
          isMultiFileLang: false
        }
      } else if (this.isMultiFileLang) {
        let root = this.$_.cloneDeep(this.project)
        this.getSanitizedScriptIt(root.treeData)
        let script = JSON.stringify({ home: root.home, treeData: root.treeData })
        data = {
          script: script,
          libs: (this.libraries) ? this.libraries.join(' ') : '',
          versionIndex: this.versionIndex,
          projectKey: this.projectKey,
          isMultiFile: true
        }
      } else {
        data = {
          script: window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue(),
          libs: (this.libraries) ? this.libraries.join(' ') : '',
          versionIndex: this.versionIndex,
          isMultiFileLang: false
        }
      }

      data.filename = (this.isSaveAs || !this.project || !this.project.id) ? this.doodleForm.projectName : this.project.name
      data.id = (this.isSaveAs || !this.project || !this.project.id) ? '' : this.project.id
      data.lang = this.language

      let saveLogic = () => {
        this.executeAPIWitoutValiation({
          url: '/api/doodle/save',
          data: data,
          method: 'post',
          successAction: (result) => {
            eventBus.$emit((this.language === 'html') ? 'HtmlIdeProjectSaved' : 'IdeProjectSaved', result.project)
          },
          markCompleted: true,
          form: this.doodleForm,
          jdaCategory: window.jda.CATEGORY.IDE,
          jdaEvent: (this.isSaveAs) ? 'save-as' : 'save',
          jdaLabel: this.language
        })
      }

      if (this.isMultiFileLang) {
        this.doodleForm.inProgress = true
        eventBus.$emit('IdeMultiFileSyncAndAct', {
          action: saveLogic,
          errorForm: this.doodleForm.errorMessage,
          errorMessage: 'Unable to Sync files with Server. Please try again or contact JDoodle Support'
        })
      } else {
        saveLogic()
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
