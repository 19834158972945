<template>
  <div class="ide-menu-box"  v-bind:class="{'is-active': showLoginMessage}">
    <div class="box">
      <div class="has-text-centered confirm-text">{{loginMessage}}</div>
      <div class="columns is-mobile" >
        <div class="column is-6 has-text-right">
          <button class="button is-small is-rounded has-text-weight-bold is-info" @click="signIn">Sign In</button>
        </div>
        <div class="column is-6 has-text-left">
          <button class="button is-small is-rounded has-text-weight-semibold" @click="closeSignInMessage">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'ideSignInMessage',
  props: ['showLoginMessage', 'loginMessage'],
  methods: {
    closeSignInMessage () {
      this.$emit('closeLoginMessage')
    },
    signIn () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Login'))
    }
  }
}
</script>

<style scoped lang="scss">

</style>
