<template>
  <div>
    <div class="instant-share-box ide-menu-box" v-bind:class="{'is-active': showShareMessage}">
      <div class="box">
        <DoodleGeneralMiniForm title=""
                               in-progress-message="JDoodle is trying to share the project, Please wait..."
                               success-message="Instant Share Created Successfully."
                               action-text="Create Share"
                               :form="doodleForm"
                               :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                               :errorMessageText="doodleForm.errorMessage"
                               @action="triggerInstantShare"
                               @close="$emit('closeInstantShare')"
                               isMini="true">
          <div class="confirm-text" v-show="!doodleForm.inProgress && !doodleForm.completed && !doodleForm.errorMessage">Instant Share creates share<span v-if="!isMultiFileLang">/embed</span> urls for the
            current program, which can be used to share
            <span v-if="!isMultiFileLang"> or embed</span>.
            This share is a one time snapshot of the current program. Further changes in the program will not reflect in
            the share.
            Copy the URLs immediately as every instant share creates a new copy of the program with a new URL.
          </div>
          <div id="instantShareContainer" class="g-recaptcha" data-size="invisible" data-sitekey="6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi"></div>
        </DoodleGeneralMiniForm>
      </div>
    </div>
    <div class="ide-menu-form-box" v-bind:class="{'is-active': showShareDetail}">
      <div class="box is-paddingless">
        <div class="has-text-weight-semibold has-text-centered is-size-7 copied-to-clipboard"
             v-show="copiedToClip">{{showCopiedMessageText}}
        </div>
        <div class="share-div">
          Share URL <br/><span class="share-box">{{shareUrl}}</span>
          <a @click="copyToClipboard(shareUrl)">
            <font-awesome-icon icon="copy" class="has-text-danger"/>
          </a>
        </div>
        <div class="share-div" v-if="!isMultiFileLang">
          Embed URL <br/><span class="share-box">{{embedUrl}}</span>
          <a @click="copyToClipboard(embedUrl)">
            <font-awesome-icon icon="copy" class="has-text-danger"/>
          </a>
        </div>
        <div class="column is-12 has-text-centered">
          <button class="button is-small is-rounded has-text-weight-semibold" @click="$emit('toggle')">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import DoodleGeneralMiniForm from '../Utils/DoodleGeneralMiniForm'
import { IDE_CONST, ROBOT_CONST } from '../../assets/javascript/constants'
import { eventBus } from '../../assets/javascript/event-bus'

export default {
  name: 'ideInstantShare',
  components: { DoodleGeneralMiniForm },
  mixins: [formMixin],
  data () {
    return {
      showShareMessage: true,
      showShareBox: false,
      showShareDetail: false,
      copiedToClip: false,
      showCopiedMessageText: '',
      shareUrl: '',
      embedUrl: '',
      robotCheckConst: 'InstantShareRobotCheck'
    }
  },
  props: ['language', 'mode', 'versionIndex', 'project', 'javaLibraries', 'libraries', 'isMultiFileLang', 'projectKey', 'blocklyWorkspace'],
  mounted () {
    eventBus.$on(this.robotCheckConst, (data) => {
      if (data.result === true) {
        this.instantShare()
      } else {
        this.doodleForm.errorMessage = ROBOT_CONST.ROBOT_ERROR
      }
    })
  },
  beforeDestroy () {
    eventBus.$off(this.robotCheckConst)
  },
  methods: {
    copyToClipboard: function (txt) {
      window.navigator.clipboard.writeText(txt).then((e) => {
        this.copiedToClip = true
        this.showCopiedMessageText = 'Copied to clipboard!'
      }, (e) => {
        this.copiedToClip = true
        this.showCopiedMessageText = 'Copy to clipboard failed!'
      })

      this.$_.delay(() => {
        this.copiedToClip = false
      }, 3000)
    },
    triggerInstantShare () {
      eventBus.$emit(ROBOT_CONST.VALIDATE_ROBOT_CHECK, { requester: this.robotCheckConst })
    },
    instantShare () {
      let url = '/api/doodle/instantShare'
      let event

      let data

      if (this.isMultiFileLang) {
        let root = this.$_.cloneDeep(this.project)
        let script = JSON.stringify({ home: root.home, treeData: root.treeData })
        data = {
          script: script,
          projectKey: this.projectKey,
          isMultiFile: true
        }
      } else if (this.language === 'blockly') {
        data = {
          script: JSON.stringify(window.Blockly.serialization.workspaces.save(this.blocklyWorkspace)),
          isMultiFileLang: false
        }
      } else {
        data = {
          script: window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue(),
          isMultiFileLang: false
        }
      }

      data.lang = this.language
      data.versionIndex = this.versionIndex
      data.libs = (this.javaLibraries) ? this.javaLibraries.join(' ') : ''

      let shareInstantLogic = () => {
        this.executeAPI({
          url: url,
          data: data,
          method: 'post',
          successAction: (data) => {
            this.shareUrl = data.url
            this.embedUrl = data.embedUrl

            this.showShareMessage = false
            this.showShareDetail = true
          },
          failureAction: (status) => {
            if (status === 403) {
              this.doodleForm.errorMessage = ROBOT_CONST.ROBOT_ERROR
              this.$store.commit('clearRobotCheck')
            }
          },
          markCompleted: true,
          form: this.doodleForm,
          jdaCategory: window.jda.CATEGORY.IDE,
          jdaEvent: event,
          jdaLabel: this.language
        })
      }

      if (this.isMultiFileLang) {
        this.doodleForm.inProgress = true
        eventBus.$emit('IdeMultiFileSyncAndAct', {
          action: shareInstantLogic,
          errorForm: this.doodleForm.errorMessage,
          errorMessage: 'Unable to sync files with the server. Please try again or contact JDoodle support'
        })
      } else {
        shareInstantLogic()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .share-div {
    font-size: 0.9em;
    font-weight: 600;
    padding: 0.6em;
  }

  .share-box {
    display: inline;
    padding: 0.5em;
  }

  .copied-to-clipboard {
    padding-top: 0.8em;
  }

  .instant-share-box.ide-menu-box.is-active {
    height: 300px;
  }

</style>
