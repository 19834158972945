export const HTML_IDE_UTIL = {
  getHtmlCode (docType, htmlHead, htmlBody, jsCode, cssCode) {
    return `${docType}
<html>
<head>
  ${htmlHead.replace(/^/g, '\t')}
</head>
<body>
  ${htmlBody.replace(/^/g, '\t')}

  <script type="text/javascript">
    ${jsCode.replace(/^/g, '\t').replace(/\n/g, '\n\t\t')}
  </script>

  <style type="text/css">
    ${cssCode.replace(/^/g, '\t').replace(/\n/g, '\n\t\t')}
  </style>
</body>
</html>
`
  }
}
